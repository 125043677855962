import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Card from "react-bootstrap/Card"

export const query = graphql`
  query($slug: String!) {
    blogDataset(SlugURL: { eq: $slug }) {
      Category
      Content
      Excerpt
      FeaturedImageURL
      Id
      SlugURL
      Title
      SEO {
        param
        value
      }
    }
  }
`
const Blog = ({ data }) => {
  const blog = data.blogDataset
  
  const [meta, setMeta] = useState({
    title: "",
    description: "",
    image: "",
    keywords: "",
    url: "",
    author: "",
  })

  useEffect(() => {
    {
      blog.SEO &&
        blog.SEO.map(item => {
          switch (item.param) {
            case "Title":
              setMeta(prevState => {
                return { ...prevState, title: item.value ? item.value : "" }
              })
              break

            case "Description":
              setMeta(prevState => {
                return {
                  ...prevState,
                  description: item.value ? item.value : "",
                }
              })
              break

            case "Url":
              setMeta(prevState => {
                return { ...prevState, url: item.value ? item.value : "" }
              })
              break

            case "Image":
              setMeta(prevState => {
                return { ...prevState, image: item.value ? item.value : "" }
              })
              break

            case "Keywords":
              setMeta(prevState => {
                return {
                  ...prevState,
                  keywords: item.value ? item.value : "",
                }
              })
              break

            case "Author":
              setMeta(prevState => {
                return { ...prevState, author: item.value ? item.value : "" }
              })
              break

            default:
            // Default code
          }
        })
    }
  }, [blog.SlugURL])

  return (
    <Layout
      title={meta.title}
      description={meta.description}
      image={meta.image}
      keywords={meta.keywords}
      url={meta.url}
      author={meta.author}
    >
      <hr className="mt-0 mb-4 d-none d-md-block" />
      <div class="blog-container">
        <div class="row">
          <div class="col-md-12">
            <Card.Img
              variant="top"
              style={{ maxWidth: "800px" }}
              src={blog.FeaturedImageURL}
            />
          </div>
          <div class="col-md-12 mt-4 blogLayoutOuter">
            <div dangerouslySetInnerHTML={{ __html: blog.Content }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Blog
